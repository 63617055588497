import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Layout from '../views/Layout'
import store from '@/store'
import EventBus from '../utils/EventBus'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'case',
    component: Layout,
    children: [
      {
        path: '/case',
        name: 'caseManage',
        component: () =>
          import(/* webpackChunkName: "case" */ '../views/caseManage'),
      },
      {
        path: '/case-opt',
        name: 'caseOpt',
        component: () =>
          import(
            /* webpackChunkName: "case-opt" */ '../views/caseManage/case-opt'
          ),
      },
      {
        path: '/king',
        name: 'KingManage',
        component: () =>
          import(/* webpackChunkName: "king" */ '../views/KingManage'),
      },

      {
        path: '/king-detail/:id',
        name: 'KingDetail',
        component: () =>
          import(
            /* webpackChunkName: "king" */ '../views/KingManage/king-detail'
          ),
      },
      {
        path: '/order',
        name: 'OrderManage',
        component: () =>
          import(/* webpackChunkName: "order" */ '../views/OrderManage'),
      },
      {
        path: '/custom',
        name: 'CustomManage',
        component: () =>
          import(/* webpackChunkName: "custom" */ '../views/CustomManage'),
      },
      {
        path: '/system',
        name: 'SystemManage',
        redirect: '/system/company',
        component: () =>
          import(/* webpackChunkName: "system" */ '../views/SystemManage'),
        children: [
          {
            path: 'company',
            name: 'company',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/company'
              ),
          },
          {
            path: 'swiper',
            name: 'swiper',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/IndexManage'
              ),
          },
          {
            path: 'caseExamine',
            name: 'caseExamine',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/caseExamine'
              ),
          },
          {
            path: 'staff',
            name: 'staff',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/staff'
              ),
          },
          {
            path: 'payment',
            name: 'payment',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/payment'
              ),
          },
          {
            path: 'style',
            name: 'style',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/styleManage'
              ),
          },
          {
            path: 'color',
            name: 'color',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/colorStyle'
              ),
          },
          {
            path: 'flower',
            name: 'flower',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/flower'
              ),
          },
          {
            path: 'dress',
            name: 'dress',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/dress'
              ),
          },
          {
            path: 'music',
            name: 'music',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/musicManage'
              ),
          },
          {
            path: 'template',
            name: 'template',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/templateManage'
              ),
          },
          {
            path: 'appointment',
            name: 'appointment',
            component: () =>
              import(
                /* webpackChunkName: "appointment" */ '../views/SystemManage/subViews/appointment'
              ),
          },
          {
            path: 'password',
            name: 'password',
            component: () =>
              import(
                /* webpackChunkName: "custom" */ '../views/SystemManage/subViews/passwordManage'
              ),
          },
        ],
      },
      {
        path: '/planner',
        name: 'Planner',
        component: () =>
          import(/* webpackChunkName: "planner" */ '../views/Planner'),
      },
    ],
  },
  {
    path: '/case-detail/:id',
    name: 'caseDetail',
    component: () =>
      import(
        /* webpackChunkName: "case-detail" */ '../views/caseManage/case-detail'
      ),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  // {
  //   path: '/active',
  //   name: 'active',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/temp/active.vue'),
  //   children: [
  //     {
  //       path: 'jieshao',
  //       name: 'jieshao',
  //       component: () =>
  //         import(/* webpackChunkName: "about" */ '../views/temp/jieshao.vue'),
  //     },
  //     {
  //       path: 'jingqu',
  //       name: 'jingqu',
  //       component: () =>
  //         import(/* webpackChunkName: "about" */ '../views/temp/jingqu.vue'),
  //     },
  //
  //     {
  //       path: 'luyou',
  //       name: 'luyou',
  //       component: () =>
  //         import(/* webpackChunkName: "about" */ '../views/temp/luyou.vue'),
  //     },
  //     {
  //       path: 'news',
  //       name: 'news',
  //       component: () =>
  //         import(/* webpackChunkName: "about" */ '../views/temp/news.vue'),
  //     },
  //     {
  //       path: 'shops',
  //       name: 'shops',
  //       component: () =>
  //         import(/* webpackChunkName: "about" */ '../views/temp/shops.vue'),
  //     },
  //     {
  //       path: 'gonglue',
  //       name: 'gonglue',
  //       component: () =>
  //         import(/* webpackChunkName: "about" */ '../views/temp/gonglue.vue'),
  //     },
  //     {
  //       path: 'about',
  //       name: 'about',
  //       component: () =>
  //         import(/* webpackChunkName: "about" */ '../views/temp/about.vue'),
  //     },
  //     {
  //       path: 'active_text/:id',
  //       name: 'active_text',
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "about" */ '../views/temp/active_text.vue'
  //         ),
  //     },
  //   ],
  // },
]

const router = new VueRouter({
  mode: 'history', // 上线前添加
  routes,
})
const whiteList = ['/login']
router.beforeEach((to, from, next) => {
  EventBus.$httpRequestList.map((item, index) => {
    item()
    delete EventBus.$httpRequestList[index]
  })
  // next()
  // /* 联调注释
  if (store.getters.token) {
    if (to.path === '/login') {
      //已经登录，直接跳转
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      //在白名单中直接进入
      next()
    } else {
      //否则到登录页重新登录
      next({
        name: 'login',
      })
    }
  }

  // */
})
export default router
