/**
 * @Author target
 * @Date 2021/9/4 16:53
 * @Version 1.0
 */
import Http from '@/utils/http'

export default {
  findCustom: (params) => Http.get(`/api/v1/client-info/find`, { params }),
  findSource: (params) => Http.get(`/api/v1/client-source/find`, { params }),
  insertCustom: (data) => Http.post(`/api/v1/client-info/insert`, data),
  modifyCustom: (data) => Http.post(`/api/v1/client-info/modify`, data),
  removeCustom: (data) => Http.post(`/api/v1/client-info/remove`, data),
  allocationCustom: (data) => Http.post(`/api/v1/client-info/allocation`, data),
  findAppointment: (params) => Http.get(`/api/v1/appointment/find`, { params }),
  // findOrderType: (params) => Http.get(`/api/v1/order-type/find`, { params }),
  modifyAppointment: (data) => Http.post(`/api/v1/appointment/modify`, data),
  removeAppointment: (data) => Http.post(`/api/v1/appointment/remove`, data),
}
