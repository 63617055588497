import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import resetMessage from '@/utils/resetMessage'
import '@/icons' // icon
import 'amfe-flexible'
import animated from 'animate.css' // npm install animate.css --save安装，在引入
//引入swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueCropper from 'vue-cropper'
// 引入swiper样式
import 'swiper/dist/css/swiper.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import * as filter from '@/filters'
import 'element-ui/lib/theme-chalk/index.css'
import introVue2 from 'intro-vue2'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
Vue.prototype.$driver = new Driver({
  allowClose: false, //禁止点击外部关闭
  doneBtnText: '我知道了', // 完成按钮标题
  closeBtnText: '跳过', // 关闭按钮标题
  nextBtnText: '下一步', // 下一步按钮标题
  prevBtnText: '上一步', // 上一步按钮标题
})
Vue.use(introVue2)
Object.keys(filter.default).forEach((key) => {
  Vue.filter(key, filter.default[key])
})
Viewer.setDefaults({
  zIndex: 8888,
  navbar: false,
  button: true,
  toolbar: {
    zoomIn: false,
    zoomOut: false,
    oneToOne: false,
    reset: false,
    prev: {
      show: true,
      size: 'large',
    },
    play: {
      show: true,
      size: 'large',
    },
    next: {
      show: true,
      size: 'large',
    },
    rotateLeft: false,
    rotateRight: false,
    flipHorizontal: false,
    flipVertical: false,
  },
  title: false,
})
// 全局注册 vue
Vue.use(Viewer)
//挂载swiper
Vue.use(VueAwesomeSwiper)

Vue.use(VueCropper)
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)
Vue.use(animated)
// vue 模块
// import ElementUI from 'element-ui'

// vuecss
// import 'element-ui/lib/theme-chalk/index.css'

// 全局注册 vue
// Vue.use(ElementUI)

//this.$router.push 相同地址报错的解决办法
import Router from 'vue-router' // 需要引入一下路由才会 有push方法

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

// 引入 初始化 样式
import './assets/css/reset.css'

// 引入 自定义 全局css
import './assets/css/gbl.css'
Vue.prototype.$message = resetMessage
Vue.prototype.$EventBus = new Vue()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
