import Axios from 'axios'
import store from '@/store'
import resetMessage from '@/utils/resetMessage'
import EventBus from './EventBus'
import router from '../router'
const CancelToken = Axios.CancelToken
EventBus.$httpRequestList = []
const service = Axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 0,
  validateStatus(status) {
    switch (status) {
      case 400:
        resetMessage.error('请求出错')
        break
      case 401:
        resetMessage.error({
          message: '授权失败，请重新登录',
          onClose() {
            router.replace('/login')
            store.dispatch('Logout').then(() => {
              // 用户登录界面提示
              // Cookies.set("point", 401);
            })
          },
        })

        break
      case 412:
        resetMessage.warning({
          message: '授权失败，请重新登录',
        })
        router.replace('/login')
        break
      case 403:
        resetMessage.warning({
          message: '拒绝访问',
        })
        break
      case 404:
        resetMessage.error({
          message: '请求错误,未找到该资源',
        })
        break
      case 500:
        resetMessage.error({
          message: '服务端错误',
        })
        break
    }
    return status >= 200 && status < 300
  },
})

service.interceptors.request.use(
  (config) => {
    // console.log(config) //准备做参数全局处理
    if (config.url !== '/api/v1/user/login') {
      if (store.getters.token) {
        config.headers['token'] = store.getters.token
      }
    }

    config.headers['Content-Type'] = 'application/json'
    config.cancelToken = new CancelToken((c) => {
      EventBus.$httpRequestList.push(c)
    })
    return config
  },
  (error) => Promise.reject(error)
)

service.interceptors.response.use(
  (response) => {
    if (response.config.responseType === 'arraybuffer') {
      return response
    }
    if (response.data.status == 401) {
      resetMessage.error({
        message: response.data.message,
        onClose() {
          if (store.getters.token) {
            store.dispatch('user/Logout').then(() => {
              // 用户登录界面提示
              router.replace('/login')
              // Cookies.set("point", 401);
            })
          } else {
            router.replace('/login')
          }
        },
      })
    }
    if (response.data.status && response.data.status !== 200) {
      resetMessage.error({
        message: response.data.message,
      })
    }
    return response.data
  },
  (error) => {
    // 兼容blob下载出错json提示
    return Promise.reject(error)
  }
)
export default service
