/**
 * @Author target
 * @Date 2021/9/10 19:16
 * @Version 1.0
 */
import COS from 'cos-js-sdk-v5'
import dayjs from 'dayjs'
import MD5 from 'js-md5'
// SECRETID 和 SECRETKEY请登录 https://console.cloud.tencent.com/cam/capi 进行查看和管理
const cos = new COS({
  // getAuthorization: function (options, callback) {
  //   console.log(123);
  //   console.log(options, callback);
  // },
  //appid:1302493395  ap-chengdu
  SecretId: 'AKIDLVPlqeCTTCeqlQqzrSmsoa9TWIn1zg6G',
  SecretKey: 'wytZ0GGzRcW9ZjcFcjZ8PP82f04rNbYE',
})
function getFileName(file) {
  console.log(file)
  let fileName = file.name
  let nowDate = dayjs().format('YYYYMMDD')
  let ts = MD5(fileName)
  let suffix = fileName.substr(fileName.lastIndexOf('.'))
  return `web-upload/${nowDate}/${ts}${suffix}`
}

export default function uploadFile(file) {
  return new Promise((resolve, reject) => {
    let fileName = getFileName(file)
    cos.putObject(
      {
        Bucket: 'quexi-1302493395' /* 必须 */,
        Region: 'ap-chengdu' /* 存储桶所在地域，必须字段 */,
        Key: fileName /* 必须 */,
        StorageClass: 'STANDARD',
        Body: file, // 上传文件对象
        onProgress: function () {
          // console.log(JSON.stringify(progressData));
        },
      },
      function (err, data) {
        // console.log(err || data);
        if (err) {
          reject(err)
        } else {
          let result = {
            status: data.statusCode,
            data: `//${data.Location}`,
          }
          resolve(result)
        }
      }
    )
  })
}
