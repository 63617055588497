/**
 * @Author target
 * @Date 2021/9/10 17:03
 * @Version 1.0
 */
export default {
  formatGender(key) {
    const GenderMap = {
      0: '女',
      1: '男',
      undefined: '未知',
      '': '未知',
    }
    return GenderMap[key]
  },
}
