/**
 * @Author target
 * @Date 2021/9/11 15:46
 * @Version 1.0
 */
import API from '@/api'
export default {
  namespaced: true,
  state: {
    found: {
      firstFundRatio: 0,
      secondFundRatio: 0,
      thirdFundRatio: 0,
    },
    colorData: [],
    styleData: [],
    systemInfo: {},
  },
  mutations: {
    SET_FOUND: (state, found) => {
      state.found = found
    },
    SET_STYLE_DATA: (state, styleData) => {
      state.styleData = styleData
    },
    SET_COLOR_DATA: (state, colorData) => {
      state.colorData = colorData
    },
    SYSTEM_INFO: (state, data) => {
      state.systemInfo = data
    },
  },
  actions: {
    getStyleData({ commit }) {
      return new Promise((resolve, reject) => {
        let params = {
          pageable: false,
        }
        API.findStyle(params)
          .then((res) => {
            commit('SET_STYLE_DATA', res.data)
            resolve(res.data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    getColorData({ commit }) {
      return new Promise((resolve, reject) => {
        let params = {
          pageable: false,
        }
        API.findColor(params)
          .then((res) => {
            commit('SET_COLOR_DATA', res.data)
            resolve(res.data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    getFundRatio({ commit }) {
      return new Promise((resolve) => {
        API.findFundRatio({ pageable: false }).then((res) => {
          if (res.status === 200 && res.data.length) {
            commit('SET_FOUND', res.data[0])
          }
        })
      })
    },
    getSystemDetail({ commit }) {
      return new Promise((resolve) => {
        API.systemDetail({ pageable: false }).then((res) => {
          if (res.status === 200) {
            commit('SYSTEM_INFO', res.data)
            resolve(res.data)
          }
        })
      })
    },
  },
}
