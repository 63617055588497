<template>
  <div id="app" data-server-rendered="true">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() {
    console.log('你在干嘛')
    this.$store.dispatch('system/getSystemDetail')
  },
  methods: {},
}
</script>
<style scoped lang="scss">
@import './assets/css/iconfont.css';
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
// 最大 768px
@media (max-width: 768px) {
}
// 最小 768px  到  最大 922px
@media (min-width: 768px) and (max-width: 992px) {
}
// 最小 992px   到 最大 1200px
@media (min-width: 992px) and (max-width: 1200px) {
}
// 最小1200px
@media (min-width: 1200px) {
}
</style>
