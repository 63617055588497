<template>
  <div id="GoTop" @click="GoTop()">
    <span class="glyphicon glyphicon-chevron-up"></span>
  </div>
</template>
<script>
export default {
  name: 'GoTop',
  data() {
    return {
      flag: false,
    }
  },
  methods: {
    GoTop() {
      // console.log(document.documentElement.scrollTop)
      ;(function smoothscroll() {
        var currentScroll = document.getElementById('appWrap').scrollTop
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll)
          document
            .getElementById('appWrap')
            .scrollTo(0, currentScroll - currentScroll / 10)
        }
      })()
    },
  },
}
</script>
<style scoped>
#GoTop {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  cursor: pointer;
}
#GoTop > span {
  display: block;
  width: 100%;
  height: 100%;
  color: rgb(8, 162, 233);
  font-size: 30px;
}
</style>
