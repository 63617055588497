/**
 * @Author target
 * @Date 2021/9/3 18:48
 * @Version 1.0
 */
import Http from '@/utils/http'
import uploadFile from '@/utils/uploadFile'
export default {
  uploadFile: (file) => uploadFile(file),
  // uploadFile: (data) =>
  //   Http.post('/api/v1/third/store', data, {
  //     headers: { 'content-type': 'multipart/form-data' },
  //   }),
  // downloadFile: (params) =>
  //   Http.get('/api/v1/third/down', { params, responseType: 'arraybuffer' }),

  findFundRatio: (params) => Http.get('/api/v1/fund-ratio/find', { params }),
  modifyFundRatio: (data) => Http.post('/api/v1/fund-ratio/modify', data),
  //案例轮播
  findCarousel: (params) => Http.get('/api/v1/case/find-carousel', { params }),
  modifyCarousel: (data) => Http.post('/api/v1/case/modify-carousel', data),

  // 速招
  insertPlaner: (data) => Http.post('/api/v1/invite/insert', data),
  // 生成
  createContract: (params) =>
    Http.get('/api/v1/contract/create', {
      params,
      responseType: 'arraybuffer',
    }),
}
