/**
 * @Author target
 * @Date 2021/9/4 17:11
 * @Version 1.0
 */
import Http from '@/utils/http'

export default {
  findOrder: (params) => Http.get(`/api/v1/order-info/find`, { params }),
  insertOrder: (data) => Http.post(`/api/v1/order-info/insert`, data),
  modifyOrder: (data) => Http.post(`/api/v1/order-info/modify`, data),
  removeOrder: (data) => Http.post(`/api/v1/order-info/remove`, data),
  detailOrder: (params) =>
    Http.get(`/api/v1/order-info/order-detail`, { params }),
  // 类型
  findOrderType: (params) => Http.get(`/api/v1/order-type/find`, { params }),
  //列表
  findOrderRace: (params) => Http.get(`/api/v1/order-race/find`, { params }),
  modifyOrderRace: (data) => Http.post(`/api/v1/order-race/modify`, data),
  // 修改
  insertWrmodify: (data) => Http.post(`/api/v1/order/wrmodify/insert`, data),
  planWarriors: (data) => Http.post(`/api/v1/order-info/warriors-plan`, data),
  modifyPlanWarriors: (data) =>
    Http.post(`/api/v1/order-info/warriors-plan/modify`, data),
  // findWrmodify: (params) => Http.get(`api/v1/order/wrmodify/find`, { params }),
  //订单流程
  findOrderFlow: (params) =>
    Http.get(`/api/v1/order-flow/find-flow`, { params }),
  findOrderFlowDetail: (params) =>
    Http.get(`/api/v1/order-flow/find`, { params }),
  insertOrderFlow: (data) => Http.post(`/api/v1/order-flow/insert`, data),
}
