<template>
  <div class="login-container">
    <template v-if="systemInfo.login && systemInfo.login.background">
      <img
        v-if="systemInfo.login.background.type != 'video'"
        class="bg"
        :src="systemInfo.login.background.value"
        alt=""
      />
      <video
        v-else
        class="bg"
        muted
        loop
        preload
        autoplay
        :src="systemInfo.login.background.value"
      ></video>
    </template>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="top-logo">
        <div class="logo">
          <img src="./images/logo-login@2x.png" alt="" />
        </div>
        <div class="register-btn" v-if="type === 1">
          没有账号? 点击
          <el-link :underline="false" @click="handleChangeType(2)"
            >注册</el-link
          >
        </div>
        <div class="register-btn" v-if="type === 2">
          已有账号? 点击
          <el-link :underline="false" @click="handleChangeType(1)"
            >登录</el-link
          >
        </div>
        <div class="register-btn" v-if="type === 3">
          返回
          <el-link :underline="false" @click="handleChangeType(1)"
            >登录</el-link
          >
        </div>
      </div>
      <template v-if="type === 1">
        <div class="title-container">
          <h3 class="title">登 录</h3>
          <span>Log in</span>
        </div>

        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="phone" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入登录账号"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
          />
          <span class="svg-container show-pwd" @click="showPwd">
            <svg-icon
              class="icon"
              :icon-class="passwordType === 'password' ? 'open' : 'eye'"
            />
          </span>
        </el-form-item>
        <div class="tips">
          <span></span>
          <div class="download">
            <el-link
              class="forgot-password"
              @click="handleChangeType(3)"
              :underline="false"
              >忘记密码？</el-link
            >
            <a class="down-btn" href="/download/婚玺.exe" target="_blank"
              >客户端下载</a
            >
          </div>
        </div>

        <el-button
          :loading="loading"
          type="info"
          class="btn-enter"
          style="width: 100%"
          @click.native.prevent="handleLogin"
          >登 录</el-button
        >
      </template>
      <template v-else-if="type === 2">
        <div class="title-container register">
          <h3 class="title">注 册</h3>
          <span>Register</span>
        </div>

        <!--        <el-form-item prop="username">-->
        <!--          <span class="svg-container">-->
        <!--            <svg-icon icon-class="user" />-->
        <!--          </span>-->
        <!--          <el-input-->
        <!--            ref="username"-->
        <!--            v-model="loginForm.phone"-->
        <!--            placeholder="请输入手机号码"-->
        <!--            name="username"-->
        <!--            type="text"-->
        <!--            tabindex="1"-->
        <!--            auto-complete="off"-->
        <!--          />-->
        <!--        </el-form-item>-->

        <el-form-item prop="phone">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="phone" />
          </span>
          <el-input
            ref="password"
            v-model="loginForm.phone"
            type="text"
            placeholder="请输入手机号码"
            name="phone"
            tabindex="1"
            :readonly="isSend"
            auto-complete="off"
          />
          <el-link
            :underline="false"
            class="show-pwd send-code"
            :disabled="isSend"
            @click="handleSendCode"
          >
            {{ verify }}
          </el-link>
        </el-form-item>
        <el-form-item prop="smsCode">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="company" />
          </span>
          <el-input
            ref="smsCode"
            v-model="loginForm.smsCode"
            type="text"
            placeholder="请输入验证码"
            name="smsCode"
            tabindex="2"
            auto-complete="off"
          />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="off"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'open' : 'eye'"
            />
          </span>
        </el-form-item>
        <el-form-item prop="company">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="company" />
          </span>
          <el-input
            ref="company"
            v-model="loginForm.company"
            type="text"
            placeholder="请输入公司名称"
            name="company"
            tabindex="3"
            auto-complete="off"
          />
        </el-form-item>
        <el-button
          :loading="loading"
          type="info"
          class="btn-enter register"
          style="width: 100%"
          @click.native.prevent="handleRegister"
          >注 册</el-button
        >
      </template>
      <template v-else-if="type === 3">
        <div class="title-container">
          <h3 class="title">重置密码</h3>
          <span>Reset Password</span>
        </div>
        <el-form-item prop="phone">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="phone" />
          </span>
          <el-input
            ref="password"
            v-model="loginForm.phone"
            type="text"
            placeholder="请输入手机号码"
            name="phone"
            tabindex="1"
            :readonly="isSend"
            auto-complete="off"
          />
          <el-link
            :underline="false"
            class="show-pwd send-code"
            :disabled="isSend"
            @click="handleSendCode"
          >
            {{ verify }}
          </el-link>
        </el-form-item>
        <el-form-item prop="smsCode">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="company" />
          </span>
          <el-input
            ref="smsCode"
            v-model="loginForm.smsCode"
            type="text"
            placeholder="请输入验证码"
            name="smsCode"
            tabindex="2"
            auto-complete="off"
          />
        </el-form-item>
        <el-form-item prop="passItem">
          <span class="svg-container">
            <svg-icon class="icon" icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.passItem"
            :type="passwordType"
            placeholder="请输入新密码"
            name="password"
            tabindex="3"
            auto-complete="off"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'open' : 'eye'"
            />
          </span>
        </el-form-item>
        <el-button
          :loading="loading"
          type="info"
          class="btn-enter"
          style="width: 100%"
          @click.native.prevent="handleResetPassword"
          >确认修改</el-button
        >
      </template>
    </el-form>
    <div class="footer">
      <div class="copyright">
        <span
          v-if="
            systemInfo.copyright &&
            systemInfo.copyright.text &&
            systemInfo.copyright.text.value
          "
        >
          {{ systemInfo.copyright.text.value }}
        </span>
        <span v-else>
          Copyright © {{ copyRightDate }} 成都婚玺传播有限责任公司
        </span>
        <a href="http://beian.miit.gov.cn/" target="_blank">
          蜀ICP备20022355号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import MD5 from 'js-md5'
import { mapGetters } from 'vuex'
import Dayjs from 'dayjs'
export default {
  name: 'Login',
  data() {
    return {
      isSend: false,
      //验证倒计时
      countDown: 60,
      verify: '获取验证码',
      type: 1,
      loginForm: {
        username: '',
        password: '',
        phone: '',
        company: '',
        passItem: '',
        smsCode: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入账号' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
        phone: [
          { required: true, trigger: 'blur', message: '请输入手机号' },
          {
            pattern:
              /^(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
            message: '请输入正确的手机号码',
          },
        ],
        smsCode: [
          { required: true, trigger: 'blur', message: '请输入短信验证码' },
        ],
        passItem: [
          { required: true, trigger: 'blur', message: '请输入新密码' },
        ],
        company: [
          { required: true, trigger: 'blur', message: '请输入公司名称' },
        ],
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['systemInfo']),
    copyRightDate() {
      return Dayjs().format('YYYY')
    },
  },
  methods: {
    handleResetPassword() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let params = {
            phone: this.loginForm.phone,
            smsCode: this.loginForm.smsCode,
            passItem: MD5(this.loginForm.passItem),
          }
          API.updatePassword(params).then((res) => {
            if (res.status === 200) {
              this.$message({
                type: 'success',
                message: '密码修改成功',
                onClose() {
                  this.handleChangeType(1)
                },
              })
            }
          })
        }
      })
    },
    handleSendCode() {
      let phoneReg =
        /^(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (!phoneReg.test(this.loginForm.phone)) {
        this.$message.warning('请填写正确的手机号码')
        return
      }
      let that = this
      that.isSend = true
      let timer = setInterval(function () {
        that.verify = that.countDown-- + ' S'
        if (that.countDown < -1) {
          clearInterval(timer)
          that.verify = '获取验证码'
          that.countDown = 60
          that.isSend = false
          return
        }
      }, 1000)
      let params = {
        phone: that.loginForm.phone,
      }
      API.sendSms(params).then((res) => {
        console.log(res)
      })
    },
    handleChangeType(type) {
      this.type = type
      this.loginForm = {
        username: '',
        password: '',
        phone: '',
        company: '',
        passItem: '',
        smsCode: '',
      }
      this.$refs.loginForm.resetFields()
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleRegister() {
      let that = this
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          let params = {
            username: this.loginForm.phone,
            phone: this.loginForm.phone,
            password: MD5(this.loginForm.password),
            smsCode: this.loginForm.smsCode,
            roleId: 4,
          }
          API.insertUser(params).then((res) => {
            this.loading = false
            if (res.status === 200) {
              this.$message({
                type: 'success',
                message: '注册成功',
                onClose() {
                  that.handleChangeType(1)
                },
              })
            }
          })
        }
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          let params = {
            username: this.loginForm.username,
            password: MD5(this.loginForm.password),
          }
          this.$store
            .dispatch('user/login', params)
            .then((res) => {
              console.log('res', res)
              if (localStorage.getItem(res.phone) == null) {
                localStorage.setItem(res.phone, 0)
              }
              this.$router.push({ path: this.redirect || '/' })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.loading = false
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss">
.download {
  font-size: 14px;
  .down-btn {
    color: #0aaeff;
    margin-left: 10px;
  }
}
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */
$bg: #283443;
$light_gray: #333;
$cursor: #fff;
@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $light_gray;
  }
}
/* reset element-ui css */
.login-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background: url('//quexi-1302493395.cos.ap-chengdu.myqcloud.com/web-upload/20210915/1631714122973.jpg')
    no-repeat center center;
  position: relative;
  .footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    width: 100%;
    height: 40px;
    font-size: 16px;
    text-align: center;
    color: #eee;
    a {
      color: #f9f9f9;
    }
  }
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .el-input {
    display: inline-block;
    height: 50px;
    left: 5px;
    width: 85%;
    input {
      background: rgb(255, 255, 255);
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 6px 12px 6px;
      color: $light_gray;
      height: 100%;
      caret-color: $light_gray;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #fff inset !important;
        -webkit-text-fill-color: #333 !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid $cursor;
    background: $cursor;
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  position: relative;
  .login-form {
    position: absolute;
    width: 600px;
    height: 800px;
    border-radius: 40px;
    top: 50%;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    transform: translate(-50%, -50%);
    max-width: 100%;
    padding: 80px;
    box-sizing: border-box;
    overflow: hidden;
    ::v-deep.el-form-item__content {
      display: flex;
      align-items: center;
    }
    .top-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 49px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .register-btn {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.4);
        .el-link {
          color: #fff;
          font-size: 14px;
          margin-left: 3px;
        }
      }
    }
    .title-container {
      text-align: center;
      margin-bottom: 60px;
      &.register {
        margin-bottom: 40px;
        h3 {
          margin-top: 40px;
        }
      }
      h3 {
        margin-top: 70px;
        margin-bottom: 20px;
        font-size: 60px;
        font-weight: normal;
      }
      span {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .btn-enter {
      margin-top: 80px;
      height: 60px;
      border-radius: 14px;
      &.register {
        margin-top: 50px;
      }
    }
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .forgot-password {
      color: #fff;
    }
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 38px;
    box-sizing: border-box;
    display: flex;
    .icon {
      width: 22px;
      height: 22px;
    }
  }
  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0 auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
    &.send-code {
      top: 7px;
      color: #be9f68;
    }
  }
}
</style>
