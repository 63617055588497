/**
 * @Author target
 * @Date 2021/8/21 17:13
 * @Version 1.0
 */
import CaseApi from './modules/case'
import UserApi from './modules/users'
import CommonApi from './modules/common'
import SystemApi from './modules/system'
import CustomApi from './modules/custom'
import OrderApi from './modules/order'
export default {
  ...CommonApi,
  ...CaseApi,
  ...UserApi,
  ...SystemApi,
  ...CustomApi,
  ...OrderApi,
}
