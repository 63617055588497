/**
 * @Author target
 * @Date 2021/8/23 19:26
 * @Version 1.0
 */
export default {
  navList: (state) => state.menus.navList,
  isMobile: (state) => state.app.isMobile,
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  orderType: (state) => state.user.orderType,
  KingMap: (state) => state.user.KingMap,
  found: (state) => state.system.found,
  styleData: (state) => state.system.styleData,
  colorData: (state) => state.system.colorData,
  companyInfo: (state) => state.user.companyInfo,
  systemInfo: (state) => state.system.systemInfo,
}
