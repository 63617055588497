/**
 * @Author target
 * @Date 2021/9/2 18:20
 * @Version 1.0
 */
// export const mapKey = 'QTVBZ-U7KR6-7UOSQ-MVPCC-F2BB2-HSFEA'//白嫖服
export const mapKey = 'OCNBZ-WZP6D-UYQ4K-PIAF4-W5CQ3-3PFFF' //正式服
// export const mapKey = 'V7NBZ-7C3LJ-BW7FE-FQO2Z-EUF7H-Z2FWF' //测试服
export const KingMap = [
  { id: 21, name: '主持人', alias: 'Presenter' },
  { id: 22, name: '化妆师', alias: 'Dresser' },
  { id: 23, name: '摄影师', alias: 'Photographer' },
  { id: 24, name: '摄像师', alias: 'Cameraman' },
  { id: 25, name: '执行团队', alias: 'ExecTeam' },
]
export default {}
