/**
 * @Author target
 * @Date 2021/9/3 19:35
 * @Version 1.0
 */
import Http from '@/utils/http'
export default {
  //手捧花
  findFlower: (params) => Http.get('/api/v1/case-flower/find', { params }),
  insertFlower: (data) => Http.post('/api/v1/case-flower/insert', data),
  modifyFlower: (data) => Http.post('/api/v1/case-flower/modify', data),
  removeFlower: (data) => Http.post('/api/v1/case-flower/remove', data),
  //婚纱管理
  findDress: (params) => Http.get('/api/v1/case-dress/find', { params }),
  insertDress: (data) => Http.post('/api/v1/case-dress/insert', data),
  modifyDress: (data) => Http.post('/api/v1/case-dress/modify', data),
  removeDress: (data) => Http.post('/api/v1/case-dress/remove', data),
  //风格管理
  findStyle: (params) => Http.get('/api/v1/case-manner/find', { params }),
  insertStyle: (data) => Http.post('/api/v1/case-manner/insert', data),
  modifyStyle: (data) => Http.post('/api/v1/case-manner/modify', data),
  removeStyle: (data) => Http.post('/api/v1/case-manner/remove', data),
  //音乐管理
  findMusic: (params) => Http.get('/api/v1/case-music/find', { params }),
  insertMusic: (data) => Http.post('/api/v1/case-music/insert', data),
  modifyMusic: (data) => Http.post('/api/v1/case-music/modify', data),
  removeMusic: (data) => Http.post('/api/v1/case-music/remove', data),

  //颜色管理
  findColor: (params) => Http.get('/api/v1/case-color/find', { params }),
  insertColor: (data) => Http.post('/api/v1/case-color/insert', data),
  modifyColor: (data) => Http.post('/api/v1/case-color/modify', data),
  removeColor: (data) => Http.post('/api/v1/case-color/remove', data),
  // 员工管理
  findUser: (params) => Http.get('/api/v1/user/find', { params }),
  insertUser: (data) => Http.post('/api/v1/user/insert', data),
  modifyUser: (data) => Http.post('/api/v1/user/modify', data),
  removeUser: (data) => Http.post('/api/v1/user/remove', data),
  // 反馈
  insertFeedBack: (data) => Http.post('/api/v1/feedback/insert', data),
  //公司管理
  detailCompany: (params) => Http.get('/api/v1/company/detail', { params }),
  modifyCompany: (data) => Http.post('/api/v1/company/modify', data),
  //百度地图搜索
  findSuggestion: (params) => Http.get(`/ws/place/v1/suggestion`, { params }),
  systemDetail: (params) => Http.get(`/api/v1/system/detail`, { params }),
}
