/**
 * @Author target
 * @Date 2021/8/21 23:17
 * @Version 1.0
 * @desc 案例相关接口
 */
import Http from '@/utils/http'
export default {
  findCase: (params) => Http.get('/api/v1/case/find', { params }),
  insertCase: (data) => Http.post('/api/v1/case/insert', data),
  modifyCase: (data) => Http.post('/api/v1/case/modify', data),
  detailCase: (params) => Http.get('/api/v1/case/detail', { params }),
  removeCase: (data) => Http.post('/api/v1/case/remove', data),

  findWarriors: (params) => Http.get('/api/v1/warriors/find', { params }),
  modifyKing: (data) => Http.post('/api/v1/warriors/modify', data),
  findOrderWarriors: (params) =>
    Http.get('/api/v1/order/wrmodify/find', { params }),
  detailWarriors: (params) => Http.get('/api/v1/warriors/detail', { params }),
}
