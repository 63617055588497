<template>
  <!-- 头部整体盒子 -->
  <div id="header" class="container-fuild">
    <!-- 电脑导航 -->
    <div class="header-nav container">
      <!-- 导航logo -->
      <div class="header-nav-logo" id="driver">
        <img :src="companyInfo.logo" @error="handleError" />
      </div>
      <div class="header-nav-title">
        <div class="company-name">
          {{ companyInfo.name }}
        </div>
        <div class="welcome">
          <span v-if="userInfo.roleId == 3">
            欢迎您,策划师<span class="name" v-if="userInfo.nickname"
              >:{{ userInfo.nickname }}</span
            >
          </span>
          <span v-else>
            欢迎您,Boss<span class="name" v-if="userInfo.nickname"
              >:{{ userInfo.nickname }}</span
            >
          </span>
        </div>
      </div>

      <!-- 导航内容 -->
      <ul class="header-nav-wrapper">
        <template v-for="(item, index) in navList">
          <template v-if="userInfo.roleId === 4">
            <li
              @click="navClick(index, item.name)"
              :key="index"
              :class="index == navIndex ? 'active' : ''"
              :id="item.id"
              v-if="roleData.indexOf(item.path) !== -1"
            >
              <router-link :to="item.path">
                <!--{{ item.name }}-->
                <div class="nav-icon">
                  <svg-icon class="icon" :icon-class="item.icon" />
                  <i>{{ item.name }}</i>
                </div>

                <span
                  v-if="item.children.length > 0"
                  class="glyphicon glyphicon-menu-down"
                ></span>
                <!--                <i class="underline"></i>-->
              </router-link>
              <dl v-if="item.children.length > 0">
                <dt v-for="(i, n) in item.children" :key="n">
                  <router-link :to="i.path">{{ i.name }}</router-link>
                </dt>
              </dl>
            </li>
          </template>

          <li
            v-else
            @click="navClick(index, item.name)"
            :key="index"
            :id="item.id"
            :class="index == navIndex ? 'active' : ''"
          >
            <router-link :to="item.path">
              <div class="nav-icon" :title="item.name">
                <svg-icon class="icon" :icon-class="item.icon" />
                <i>{{ item.name }}</i>
              </div>

              <span
                v-if="item.children.length > 0"
                class="glyphicon glyphicon-menu-down"
              ></span>
              <!--              <i class="underline"></i>-->
            </router-link>
            <dl v-if="item.children.length > 0">
              <dt v-for="(i, n) in item.children" :key="n">
                <router-link :to="i.path">{{ i.name }}</router-link>
              </dt>
            </dl>
          </li>
        </template>
        <li class="right-nav">
          <div v-if="false && userInfo.roleId !== 4" class="btn-icon">
            <img src="../../assets/images/nav/add@2x.png" alt="" />
          </div>
          <div v-if="userInfo.icon" class="btn-icon avatar">
            <img :src="userInfo.icon" alt="" />
          </div>
          <div class="btn-icon" @click="handleLogout">
            <img src="../../assets/images/nav/quit@2x.png" alt="" />
            <i>退出</i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { KingMap } from '@/config'
import steps from '@/views/Layout/steps'
export default {
  name: 'Header',
  data() {
    return {
      roleData: ['/case', '/system', '/case?from=click'],
      navIndex: 0,
      menuName: '案例',
      menuClass: 'glyphicon glyphicon-menu-down',
      navList: [
        {
          name: '案例',
          path: '/case?from=click',
          icon: 'nav-case',
          id: 'case',
          children: [],
        },
        {
          name: '金刚',
          path: '/king',
          icon: 'nav-emp',
          id: 'king',
          children: [],
        },
        {
          name: '订单',
          path: '/order',
          icon: 'nav-order',
          id: 'order',
          children: [],
        },
        {
          name: '客户',
          path: '/custom',
          icon: 'nav-clint',
          id: 'custom',
          children: [],
        },
        {
          name: '系统',
          path: '/system',
          icon: 'nav-set',
          id: 'system',
          children: [],
        },
        // {
        //   name: '软件产品',
        //   path: '/software',
        //   children: [
        //     {
        //       name: '智能小镇管理系统',
        //       path: '/software/smartTown',
        //     },
        //     {
        //       name: '大数据管理系统',
        //       path: '/software/bigData',
        //     },
        //   ],
        // },
      ],
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'companyInfo']),
  },
  filters: {
    formatRoles(val) {
      let temp = {}
      KingMap.map((i) => {
        temp[i.id] = i.name
      })
      return temp[val]
    },
  },
  created() {
    let path = this.$route.path
    console.log(
      path,
      this.navList.map((item) => item.path)
    )
    this.navIndex = this.navList.map((item) => item.path).indexOf(path)
    // 监听事件
    window.addEventListener('scroll', function () {
      let t = $('body, html').scrollTop() // 目前监听的是整个body的滚动条距离
      console.log(t)
    })
  },
  methods: {
    handleError(e) {
      e.target.src = require('@/assets/images/logo@2x.png')
    },
    handleLogout() {
      this.$confirm('确定退出系统?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$store.dispatch('user/Logout').then(() => {
            this.$router.replace('/login')
          })
        })
        .catch((err) => err)
    },
    navClick(index, name) {
      console.log(index)
      this.navIndex = index
      sessionStorage.setItem('navIndex', index)
      this.menuName = name
    },
    menuClick() {
      if (this.menuClass == 'glyphicon glyphicon-menu-down') {
        this.menuClass = 'glyphicon glyphicon-menu-up'
      } else {
        this.menuClass = 'glyphicon glyphicon-menu-down'
      }
    },
  },
}
</script>
<style scoped lang="scss">
/* 顶部 */
#header {
  background: #fff;
  width: 100%;
  //position: fixed;
  //z-index: 1500;
  //transition: all ease 0.6s;
}
#header .header-top {
  height: 50px;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  background: #474747;
}
/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}
/* 导航栏 */
#header .header-nav {
  height: 80px;
  width: 1400px;
  margin: 0 auto;
  position: relative;
  /*border-bottom: 1px solid #e2e2e2;*/
  //position: fixed;
  box-sizing: border-box;
  .logo-name {
    display: flex;
  }
}
/* 导航栏logo */
#header .header-nav .header-nav-logo {
  width: 68px;
  height: 56px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
#header .header-nav .header-nav-title {
  width: 300px;
  height: 56px;
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
  .company-name {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  .welcome {
    font-size: 12px;
    color: #999;
    margin-right: 30px;
    .name {
      color: #333;
    }
  }
}
/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  width: 100%;
  height: 100%;
}
/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}
#header .header-nav .header-nav-wrapper {
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
  .nav-icon {
    display: flex;
    flex-direction: column;
    //font-size: 34px;
    align-items: center;
    cursor: pointer;
    line-height: initial;
    .icon {
      font-size: 24px;
      margin-bottom: 5px;
    }
    i {
      font-weight: normal;
      color: #999;
      font-size: 14px;
    }
  }
}

#header .header-nav .header-nav-wrapper .right-nav {
  margin-left: 30px;
  margin-right: -15px;
  height: 100%;
  display: flex;
  line-height: initial;
  align-items: center;
  justify-content: center;
  .btn-icon {
    height: 48px;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.avatar {
      margin-left: 30px;
      margin-right: 20px;
      height: 50px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
      //display: none;
    }
    i {
      font-size: 14px;
      font-weight: normal;
      color: #be9f68;
    }
  }
}
/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper > li {
  float: left;
  height: 100%;
  //line-height: 80px;
  margin: 0 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper > li > a {
  color: #000;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper > li > a > i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #1e73be;
}
/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper > li > a > span {
  font-size: 12px;
  transition: transform ease 0.5s;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper > li > a {
  position: relative;
}
//#header .header-nav .header-nav-wrapper > li > a:hover {
//  color: #1e73be;
//  text-decoration: none;
//}
#header .header-nav .header-nav-wrapper > li.active:after {
  content: '';
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 3px;
  z-index: 999;
  background-color: #be9f68;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover .underline {
  opacity: 0;
  display: none;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover span {
  transform: rotate(180deg);
}
/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper > li.active > a {
  //color: #1e73be;
  //text-decoration: none;
  //box-sizing: border-box;
  //border-bottom: 3px solid #1e73be;
}
/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper > li > dl {
  display: none;
  position: absolute;
  width: 168px;
  top: 80%;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 3px 1px #ccc;
  background: #fff;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper > li > dl > dt {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper > li > dl > dt > a:hover {
  text-decoration: none;
}
/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper > li:hover dl {
  display: block;
}
#header .header-nav .header-nav-wrapper > li > dl > dt:hover {
  cursor: pointer;
  background: #ccc;
}
@media screen and (max-width: 997px) {
  #header .header-nav-m {
    position: relative;
  }
  /* 导航栏logo容器 */
  #header .header-nav-m .header-nav-m-logo {
    height: 80px;
    position: relative;
  }
  /* 导航栏logo图片 */
  #header .header-nav-m .header-nav-m-logo img {
    width: 95px;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  /* 导航栏  菜单容器 */
  #header .header-nav-m .header-nav-m-menu {
    color: #fff;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    background: #474747;
    position: relative;
  }
  /* 导航栏 菜单图标 */
  #header .header-nav-m .header-nav-m-menu-wrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    width: 50px;
    height: 40px;
    color: #fff;
    z-index: 999999;
    font-size: 12px;
  }
  /* 导航栏 */
  #header .header-nav-m .header-nav-m-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background: #474747;
    z-index: 9999999;
  }
  /* 导航栏 每个导航 */
  #header .header-nav-m .header-nav-m-wrapper > li {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
  }
  /* 导航栏 每个导航下面的 a 链接 */
  #header .header-nav-m .header-nav-m-wrapper > li > a {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 0;
    position: relative;
  }
  /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
  #header .header-nav .header-nav-wrapper > li > a > span {
    font-size: 10px;
  }
}
</style>
