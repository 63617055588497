/**
 * @Author target
 * @Date 2021/8/24 13:38
 * @Version 1.0
 */
export default {
  namespaced: true,
  state: {
    navList: [
      {
        name: '案例',
        id: 1,
        path: '/',
      },
      {
        name: '五大金刚',
        id: 2,
        path: '/king',
      },
      {
        name: '订单',
        id: 3,
        path: '/order',
      },
      {
        name: '客户',
        id: 4,
        path: '/custom',
      },
      {
        name: '系统管理',
        id: 5,
        path: '/system',
      },
      {
        name: '策划师速招',
        id: 6,
        path: '/planner',
      },
    ],
  },
}
