/**
 * @Author target
 * @Date 2021/8/29 11:15
 * @Version 1.0
 */
import Http from '@/utils/http'
export default {
  login: (data) => Http.post('/api/v1/user/login', data),
  logout: (data) => Http.post('/api/v1/user/logout', data),
  sendSms: (data) => Http.post('/api/v1/third/sms', data),
  updatePassword: (data) => Http.post('/api/v1/user/passwd', data),
  userDetail: (params) => Http.get('/api/v1/user/detail', { params }),
  modifyUser: (data) => Http.post('/api/v1/user/modify', data),
  findWarriorsManner: (params) =>
    Http.get('/api/v1/warriors/find-manner', { params }),
}
