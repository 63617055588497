<template>
  <div id="appWrap" class="app-wrap">
    <div class="app-header">
      <Header></Header>
    </div>
    <div class="app-router-view">
      <div class="router-view-wrapper">
        <router-view />
      </div>
      <Footer></Footer>
      <GoTop></GoTop>
    </div>
  </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import GoTop from './GoTop'
import ResizeHandler from '@/mixins/ResizeHandler'
import { mapGetters } from 'vuex'
export default {
  name: 'layout',
  data() {
    return {}
  },
  mixins: [ResizeHandler],
  components: {
    // NavBar,
    Header,
    Footer,
    GoTop,
  },
  computed: {
    ...mapGetters(['isMobile', 'userInfo']),
  },
  created() {
    if (this.userInfo.roleId !== 4) {
      this.$store.dispatch('system/getFundRatio')
    }
    this.$store.dispatch('system/getStyleData')
    this.$store.dispatch('system/getColorData')
  },
  // mounted() {
  //   document.getElementById('appWrap').scrollTo(0, 1000)
  // },
}
</script>

<style scoped lang="scss">
.app-wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
  .app-header {
    position: sticky;
    top: 0;
    z-index: 1500;
  }
  .driver-fix-stacking {
    position: relative !important;
  }
  .app-router-view {
    //height: calc(100% - 80px);
    //height: 100%;
    .router-view-wrapper {
      min-height: calc(100% - 440px);
      //padding-bottom: 160px;
    }
  }
}
.layout {
  width: 100%;
  height: 100%;
}
</style>
