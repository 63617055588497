<template>
  <div id="footer" class="container-fluid">
    <div class="footer-nav">
      <div class="nav-list">
        <svg-icon icon-class="white-logo"></svg-icon>
        <ul class="nav">
          <li>
            <router-link tag="span" to="/case">案例</router-link>
          </li>
          <span class="separator">/</span>
          <li>
            <router-link tag="span" to="/king">五大金刚</router-link>
          </li>
          <span class="separator">/</span>
          <li v-if="userInfo.roleId !== 4">
            <router-link tag="span" to="/order">订单</router-link>
          </li>
          <span v-if="userInfo.roleId !== 4" class="separator">/</span>
          <li v-if="userInfo.roleId !== 4">
            <router-link tag="span" to="/custom">客户</router-link>
          </li>
          <span v-if="userInfo.roleId !== 4" class="separator">/</span>
          <li v-if="userInfo.roleId !== 4">
            <router-link tag="span" to="/system">系统管理</router-link>
          </li>
          <span v-if="userInfo.roleId !== 4" class="separator">/</span>
          <li @click="handleOpenFeedback">
            <span>意见反馈</span>
          </li>
        </ul>
      </div>
      <div class="btn planner" @click="handleOpenPlanner">招贤纳士</div>
    </div>
    <div class="footer-copy">
      <div
        class="copyright"
        v-if="systemInfo.copyright.text && systemInfo.copyright.text.value"
      >
        {{ systemInfo.copyright.text.value }}
        <a href="http://beian.miit.gov.cn/" target="_blank">
          蜀ICP备20022355号
        </a>
      </div>
      <div class="copyright" v-else>
        Copyright © {{ copyDate }} 成都婚玺传播有限责任公司
        <a href="http://beian.miit.gov.cn/" target="_blank">
          蜀ICP备20022355号
        </a>
      </div>
      <div class="connect">
        <svg-icon icon-class="connect"></svg-icon>
        <span v-if="systemInfo.contact">{{
          systemInfo.contact.phone.value
        }}</span>
      </div>
    </div>
    <!--  意见反馈   -->
    <el-drawer
      title="意见反馈"
      :visible.sync="feedbackVisible"
      direction="rtl"
      :wrapperClosable="false"
      :before-close="handleCloseFeedback"
    >
      <div class="feedback">
        <el-form
          ref="feedBackForm"
          :rules="feedBackFormRules"
          label-position="top"
          :model="feedBackForm"
        >
          <el-form-item prop="title">
            <el-input
              v-model="feedBackForm.title"
              placeholder="请填写标题说明"
            ></el-input>
          </el-form-item>
          <el-form-item prop="content">
            <el-input
              type="textarea"
              v-model="feedBackForm.content"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-upload
              action
              :auto-upload="false"
              list-type="picture-card"
              :on-change="handleChangeFile"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn-area">
          <el-button type="default" class="btn" @click="handleCloseFeedback"
            >取消</el-button
          >
          <el-button type="warning" class="btn" @click="handleSubmitFeedBack"
            >提交</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!--  速招  -->
    <el-drawer
      title="招贤纳士"
      :visible.sync="plannerVisible"
      direction="rtl"
      :wrapperClosable="false"
      :before-close="handleClosePlanner"
    >
      <div class="feedback">
        <el-form
          ref="plannerForm"
          :rules="plannerFormRules"
          label-position="top"
          :model="plannerForm"
        >
          <el-form-item prop="title">
            <el-input
              v-model="plannerForm.title"
              placeholder="请填写标题说明"
            ></el-input>
          </el-form-item>
          <el-form-item prop="post">
            <el-input
              type="text"
              v-model="plannerForm.post"
              placeholder="请填写招聘职位"
            ></el-input>
          </el-form-item>
          <el-form-item prop="contact">
            <el-input
              type="text"
              v-model="plannerForm.contact"
              placeholder="请填写联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="请选择性别">
            <el-radio-group v-model="plannerForm.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
              <el-radio :label="2">不限</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="请选择面试时间" prop="inviteTime">
            <el-date-picker
              v-model="plannerForm.inviteTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="12:00:00"
              placeholder="请选择面试时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item title="备注">
            <el-input
              type="textarea"
              v-model="plannerForm.remark"
              placeholder="请填写备注"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-area">
          <el-button type="default" class="btn" @click="handleClosePlanner"
            >取消</el-button
          >
          <el-button type="warning" class="btn" @click="handleSubmitPlanner"
            >提交</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Dayjs from 'dayjs'
import API from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  data() {
    return {
      feedbackVisible: false,
      plannerVisible: false,
      feedBackForm: {},
      feedBackFormRules: {},
      plannerForm: {
        inviteTime: '',
      },
      plannerFormRules: {
        title: [{ required: true, message: '请填写招聘标题' }],
        post: [{ required: true, message: '请填写招聘职位' }],
        contact: [
          { required: true, message: '请输入手机号码' },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号码',
          },
        ],
        inviteTime: [{ required: true, message: '请选择面试时间' }],
      },
      fileList: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'systemInfo']),
    copyDate() {
      return Dayjs().format('YYYY')
    },
  },
  methods: {
    handleClosePlanner() {
      this.plannerVisible = false
      this.plannerForm = {
        inviteTime: '',
      }
    },
    handleOpenPlanner() {
      this.plannerVisible = true
    },
    handleSubmitPlanner() {
      this.$refs.plannerForm.validate((valid) => {
        if (valid) {
          let params = {
            ...this.plannerForm,
          }
          API.insertPlaner(params).then((res) => {
            if (res.status === 200) {
              this.$message.success('发布成功')
              this.handleClosePlanner()
            }
          })
        }
      })
      console.log(this.plannerForm)
    },
    handleSubmitFeedBack() {
      this.$refs.feedBackForm.validate((valid) => {
        if (valid) {
          let params = {
            ...this.feedBackForm,
            pics: [],
          }
          let p = []

          this.fileList.map((item) => {
            p.push(API.uploadFile(item.raw))
          })
          Promise.all(p).then((result) => {
            result.map((i) => {
              params.pics.push(i.data)
            })
            API.insertFeedBack(params).then((res) => {
              if (res.status === 200) {
                this.$message.success('反馈成功')
                this.handleCloseFeedback()
              }
            })
          })
        }
      })
    },
    handleChangeFile(file, fileList) {
      this.fileList = fileList
    },
    handleOpenFeedback() {
      this.feedbackVisible = true
    },
    handleCloseFeedback() {
      this.feedbackVisible = false
      this.fileList = []
      this.feedBackForm = {}
    },
  },
}
</script>
<style scoped lang="scss">
.btn-area {
  display: flex;
  justify-content: space-between;
  .btn {
    flex: 1;
    margin-right: 20px;
    &.el-button--default {
      border: 1px solid #ccc;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.el-drawer__header > :first-child {
  text-align: left;
  font-size: 24px;
  color: #333;
}
.feedback {
  padding: 0 40px;
  box-sizing: border-box;
  text-align: left;
}
#footer {
  width: 100%;
  height: 280px;
  color: #fff;
  background: #474747;
  overflow: hidden;
  text-align: center;
  .footer-nav {
    width: 1400px;
    height: 160px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-list {
      display: flex;
      align-items: center;
      color: #999;
      ul {
        margin-left: 20px;
        display: flex;
        align-items: center;
        .separator {
          font-size: 14px;
          padding: 0 10px;
        }
        li {
          margin-right: 10px;
          font-size: 14px;
          color: #999;
          cursor: pointer;
        }
      }
    }
    .planner {
      border: 1px solid #999;
      border-radius: 4px;
      color: #999;
      font-size: 14px;
    }
  }
  .footer-copy {
    width: 1400px;
    height: 120px;
    margin: 0 auto;
    color: rgba(241, 241, 241, 0.4);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: #f1f1f1;
    }
    .connect {
      span {
        margin-left: 10px;
      }
    }
  }
}
</style>
